
.header {
    width: 100%;
    background-color: #4646A0;
    position: relative;
}

.header .linear {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #512AC2 0%, #7951EC 100%);
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.header .box {
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.header .box .ellipse {
    content: '';
    display: block;
    background: rgba(182, 42, 194, 0.5);
    filter: blur(100px);
    border-radius: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 500px;
    height: 500px;
    z-index: 1;
}

